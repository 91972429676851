import { Box } from '@otovo/rainbow';
import GoogleTagManagerAndOneTrustScripts from '@otovo/shared/lib/GoogleTagManagerAndOneTrustScripts';
import { NextRouter, useRouter } from 'next/router';

import { useEffect } from 'react';

import NextPageRoot from '@otovo/shared/NextPageRoot';
import { setHeadBaseUrl } from '@otovo/shared/components/Head/headContext';
import resolveTagManagerID from '@otovo/shared/lib/analytics/resolveTagManagerID';
import resolveTheme from '@otovo/shared/resolveTheme';
import { Otovo$Config } from '@otovo/shared/types/otovoweb';

import '@otovo/shared/styles/globals.css';
import workaroundBrowserExtensions from '@otovo/shared/utils/workaroundBrowserExtensions';

import MyPageHead from '../components/MyPageHead';
import MyPagePage from '../components/MyPagePage';
import { onAuthenticationDenied } from '../utils/api';
import { loadAuthTokenFromStorage } from '../utils/auth/auth';
import { logoutUser } from '../utils/auth/authService';

type Props = {
  Component: any;
  pageProps: { config: Otovo$Config };
  router: NextRouter;
  err: any;
};

function MyPage({ Component, pageProps }: Props) {
  const { config } = pageProps;
  const { asPath, replace } = useRouter();

  if (__isBrowser__) {
    window.routerReplace = replace;
  }

  useEffect(() => {
    if (/Chrom/.test(window.navigator.userAgent)) {
      workaroundBrowserExtensions();
    }
  }, []);

  const gtmId = resolveTagManagerID(config);

  loadAuthTokenFromStorage();
  setHeadBaseUrl(config.MYPAGE_URL);
  onAuthenticationDenied(() => {
    logoutUser();
  });

  const theme = resolveTheme(config.BU_CONFIG);

  return (
    <NextPageRoot configValues={config} theme={theme}>
      {!!gtmId && (
        <GoogleTagManagerAndOneTrustScripts
          googleTagManagerId={gtmId}
          query={asPath.split('?')[1]}
        />
      )}
      <MyPageHead />
      <MyPagePage>
        <Box flex="1 1 auto">
          <Component {...pageProps} />
          <div id="modal-root" />
        </Box>
      </MyPagePage>
    </NextPageRoot>
  );
}

export default MyPage;
